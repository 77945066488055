<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="preview-header">
                <div class="preview-title">{{ title }}</div>
                <div class="preview-action">
                    <div><download-button dom="#previewContent" :name="this.entity.requestCode + '.pdf'"/></div>
                    <div class="preview-print">
                        <a @click="print">Print</a>
                    </div>
                    <div class="preview-close" @click="showDialog = false">
                        Close
                    </div>
                </div>
            </div>
            <md-content id="previewContent" class="md-scrollbar">
                <div class="preview-content" style="width: 780px;">
                    <div style="display: flex; border: 1px solid #ccc; padding: 10px;">
                        <div style="width: 50%; display: flex; align-items: center;">
                            <img src="../../../assets/images/logo.png" style="width: 130px; height: 50px;"/>
                        </div>
                        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                            <div style="font-weight: bold; font-size: 22px;">ĐỀ NGHỊ SỬA CHỮA</div>
                            <div style="font-size: 14px;"><span>Số: </span><span style="font-weight: bold;">{{entity.requestCode}}</span></div>
                            <div><span>Ngày: </span><span>{{entity.requestDate}}</span></div>
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; align-items: center; padding: 10px 0;"> 
                        <span style="font-weight: bold; font-size: 18px;">Kính gửi: </span>
                        <span style="font-weight: bold; font-size: 18px; padding-left: 10px;">BGĐ Công ty PPL</span>
                    </div>
                    <div style="display: flex; flex-direction: column;"> 
                        <p style="padding-bottom: 10px; font-size: 14px;">Tôi tên là: <span style="font-weight: bold; font-size: 15px;">{{entity.staff.fullName}}</span></p>
                        <p>Đề nghị giải quyết cho phương tiện: <span style="font-weight: bold; font-size: 15px;">{{entity.vehicle.licensePlate}}</span> được sửa chữa theo nội dung sau: </p>
                        <p v-html="entity.requestContent" style="padding-top: 20px;"></p>
                    </div>
                    <div style="display: flex; justify-content: space-around; padding-top: 80px;"> 
                        <div v-for="item in entity.approveList" :key="item.id">
                            <div v-show="!item.isCommenter" style="display: flex; flex-direction: column; justify-content: center; ">
                                <div style="font-size: 15px; font-weight: bold;">{{item.title}}</div>
                                <div style="font-size: 15px; font-weight: bold; padding-top: 70px;">{{item.staff.fullName}}</div>
                                <div>{{item.note}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </md-content>
        </md-content>
    </md-dialog>
</template>

<script>
    import downloadButton from '../../../components/DownloadButton.vue';

    export default ({
        components: {
            downloadButton
        },
        metaInfo: {
            title: 'Đề nghị sửa chữa'
        },
        data() {
            return {
               title: 'Đề nghị sửa chữa',
               id: 0,
               showDialog: false,
               loadding: false,
               entity: { id: 0, vehicle: { licensePlate: ''}, approveList: [], staffId: '', staff: {}, vehicleId: 0, requestCode: '',requestDate: null, requestContent: '', requestType: '', isActive: true },
            }
        },
        methods: {
            async open(obj){
                this.entity = obj;
                this.showDialog = true;
            },

            print () {
                this.$htmlToPaper('previewContent');
            }
        },
    })
</script>


<style lang="css" scoped>
    .preview-title {
        font-size: 16px;
    }
    .preview-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 810px;
        min-height: 600px;
        max-width: 810px;
    }
    .md-scrollbar {
        height: 600px;
        padding-bottom: 20px;
    }
    .preview-content {
        padding: 10px 15px;
    }
</style>